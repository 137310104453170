import {Feature} from "ol";
import React, {useContext, useEffect, useState} from "react";
import MapContext from "../../contexts/MapContext";
import {Geometry} from "ol/geom";

import styles from './FacilitiyItem.module.css';
import i18n from "i18next";
import getSimpleLanguageCode from "../../services/Helpers";
import {VDIconService} from "../../services/VDIconService";
import {GeoFaIconService} from "../../services/GeoFaIconService";
import {EGeoType, ESource} from "../../services/enumarations";
import FacilityIcon from "../FacilityIcon/FacilityIcon";
import {CommonFilterItem} from "../../contexts/MapContextProvider";
import {IBaseTheme} from "../../services/interfaces";

interface FacilityItemProps {
    objectId?: string,
    onClick?: () => void,
    className?: {},
    loading?: boolean
}

const FacilityItem: React.FC<FacilityItemProps> = ({
                                                       objectId,
                                                       onClick,
                                                       loading
                                                   }) => {

    const {getState} = useContext(MapContext);

    const iconService = new GeoFaIconService();
    const vDIconService = new VDIconService();

    const lang = i18n.language;
    let ftr: Feature<Geometry> | undefined;

    const [f, setF] = useState<string>('')
    const [n, setN] = useState<string>('')

    const [theme, setTheme] = useState<CommonFilterItem>()

    const [ldng, setLdng] = useState<boolean | undefined>(true)

    useEffect(() => {
        setLdng(loading)
    }, [loading]);

    function localizeLabel(theme?: IBaseTheme): string {
        if (theme) {
            if (getSimpleLanguageCode(i18n.language) === 'da') {
                return theme.label || '***';
            } else if (getSimpleLanguageCode(i18n.language) === 'de') {
                return theme?.label_de || theme?.label || '***';
            } else {
                return theme?.label_gb || theme?.label || '***';
            }
        }
        return '--';
    }

    /*
    We check whether objectId is set and not null.
    Sometimes an object gets stuck in the modals
     */
    useEffect(() => {

        // object ID set when we call the

        let oI: string;

        if (!objectId) {
            oI = getState().featureIdInMapOverlay;
        } else {
            oI = objectId
        }

        if (oI) {

            const features: Feature[] = getState().allFeatures;
            const vdFeatures: Feature[] = getState().allVDFeatures;

            let filteredFeature = features.filter(
                o => o.getProperties().objekt_id === oI
            )

            let filteredVDFeature = vdFeatures.filter(
                o => o.getId() === oI
            )

            if (filteredFeature.length > 0) {
                ftr = filteredFeature[0]
            } else if (filteredVDFeature.length > 0) {
                ftr = filteredVDFeature[0]
            }

            let properties = ftr?.getProperties() || {}

            if (properties?.source === 'vd') {
                setN(properties?.name);
            } else {
                setN(properties?.navn);
            }

            // TODO: Use the shortened method
            if (['de', 'de-DE', 'de-CH', 'de-AT', 'de-LI', 'de-LU'].includes(i18n.language) && properties.navn_d) {
                setN(properties.navn_d);
            } else if (['en', 'en-GB', 'en-US'].includes(i18n.language) && properties.navn_uk) {
                setN(properties.navn_uk);
            }

            if (properties?.source === 'vd') {

                setTheme({
                    category: Number(properties?.category), // Replace with an appropriate category number
                    source: ESource.vd, // Select a value from the ESource enum //
                    type: EGeoType.VDPoint, // Select a value from the EGeoType enum
                });

            } else {

                let tc = undefined;
                let gt = EGeoType.Point;

                // Points
                if (properties.temakode === 5800) {
                    tc = properties?.facil_ty_k;
                }

                // Lines
                if (properties.temakode === 5802) {
                    tc = properties?.rute_ty_k;
                    gt = EGeoType.Line;
                }

                // Polygons
                if (properties.temakode === 5801) {
                    gt = EGeoType.Polygon;
                }

                setTheme({
                    category: Number(tc), // Replace with an appropriate category number
                    source: ESource.geofa, // Select a value from the ESource enum
                    type: gt, // Select a value from the EGeoType enum
                });

            }

        } else {
            console.log('NO oI');
        }
    }, [])

    useEffect(() => {
        if (theme) {
            const tt = iconService.getThemeX(theme.category, theme.type);
            const localizedLabel = localizeLabel(tt);
            setF(localizedLabel);
            if (!n) {
                setF('');
                setN(localizedLabel)
            }
        }
    }, [theme]);

    return (
        <div className={styles.container}>
            <div className={styles.bottom}>
                <div className={styles.top}>
                    {theme && <FacilityIcon className={styles.FriluftsIcon}
                                            itemClass={theme}
                                            loading={ldng}
                                            fontSize={"35px"}
                    ></FacilityIcon>
                    }
                </div>
                <div className={styles.Bc}>
                    <b>{n}</b><br/>
                </div>
                <i className={styles.facilityType}>{f}</i>
            </div>

        </div>
    )
}

export default FacilityItem;
