import {ICategoryItem, IThemegroup} from "../../interfaces/ICategoryItem";
import {CommonFilterItem} from "../../contexts/MapContextProvider";
import {ESource} from "../../services/enumarations";

// Type guard function
function isCommonFilterItem(obj: any): obj is CommonFilterItem {
    return (
        obj &&
        typeof obj === 'object' &&
        typeof obj.category === 'number' &&
        Object.values(ESource).includes(obj.source)
    );
}

function flattenThemeFilter(
    category: ICategoryItem,
    themeFilter: (IThemegroup | CommonFilterItem)[],
    groupIDs: number[] = []
) {

    // TODO: 2025;

    /*
    return themeFilter.map((m: CommonFilterItem) => {
        if (groupIDs.indexOf(m)> -1) {
            // we have a group, lets resolve it
            return category.themes.map((r: CommonFilterItem | IThemegroup) => {
                if (isCommonFilterItem(r)) {
                } else {
                    // IThemegroup
                    return r.themes
                }
            }).filter(Boolean).flat() as number[] || [];
        } else {
            return m;
        }
    }).flat() as number[] || []
     */

}

function flattenArray(arr: any[]): any[] {

    let result: any[] = [];

    if (Array.isArray(arr)) {
        arr.forEach((item) => {
            if (typeof item !== 'number') {
                result = result.concat(item.themes);
            } else {
                result.push(item);
            }
        });
    } else {
        console.error('Input is not an array');
    }

    return result;
}

export default flattenArray;
