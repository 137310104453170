import {createContext} from "react";
import * as ol from "ol";
import {IThemegroup} from "../interfaces/ICategoryItem";
import {CommonFilterItem} from "./MapContextProvider";

interface IMapContext {
    getState(): any
    // GUIDObjects: { [key: string]: string }
    Facilities: any[]
    themeFilter: (IThemegroup | CommonFilterItem)[]
    tappedFeatures: ol.Feature[]
    featuresInViewport: ol.Feature[]
    // featureInMapOverlay: ol.Feature,
    featureIdInMapOverlay: string | undefined,
    mapObject: ol.Map
    objectIDsWithPhotos: string[]
    listModalState: string
    selectedObjectId: string
    allFeatures: ol.Feature[]
    allVDFeatures: ol.Feature[]
    showListModal: boolean
    showFilterModal: boolean
    showMunicipalitiesModal: boolean
    kommunekode: number
    tapCoordinate: any
    locationStatus: number
}

const defaultMapData: IMapContext = {
    getState(): any {},
    // GUIDObjects: myGUIDObject,
    Facilities: [],
    themeFilter: [],
    tappedFeatures: [],
    // featureInMapOverlay: new ol.Feature(),
    featuresInViewport: [],
    featureIdInMapOverlay: undefined,
    mapObject: new ol.Map({}),
    objectIDsWithPhotos: [],
    listModalState: 'list',
    selectedObjectId: '',
    allFeatures: [],
    allVDFeatures: [],
    showListModal: false,
    showFilterModal: false,
    kommunekode: 550,
    showMunicipalitiesModal: false,
    tapCoordinate: undefined,
    locationStatus: 0
}

const MapContext = createContext<IMapContext>(defaultMapData);

export default MapContext;
