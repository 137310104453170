import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";

import * as ol from "ol";
import {MapEvent, View} from "ol";
import {fromLonLat, transform} from "ol/proj";
import TileLayer from "ol/layer/Tile";
import {OSM} from "ol/source";
import GeoFAService from "../../services/GeoFAService";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Control, defaults as defaultControls, ScaleLine} from "ol/control";

import MapContext from "../../contexts/MapContext";
import {IonBackdrop, IonContent, IonFab, IonFabButton, IonIcon, IonSpinner, IonToast} from "@ionic/react";

import {Circle, Fill, Stroke, Style} from "ol/style";
import {AppConfig} from "../../utils/AppConfig";

import Point from "ol/geom/Point";

import 'ol/ol.css';
import styles from "./TheMap.module.css";
import hereSVG from "../Header/here.svg";
import {accessibilityOutline, addCircleOutline, removeCircleOutline, warningOutline} from "ionicons/icons";

import {LineString, MultiPoint, SimpleGeometry} from "ol/geom";
import {IThemegroup} from "../../interfaces/ICategoryItem";
import {getCenter, getSize} from "ol/extent";

import {defaults as defaultInteractions} from 'ol/interaction';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {EXTENDEDMUNICIPALITIES} from "../../services/kommuner/ExtendedMunicpalities";
import {getLength} from "ol/sphere";
import VDService from "../../services/VDService";
import {VDIconService} from "../../services/VDIconService";
import {LOCATIONS} from "../../utils/locations";

import "../../ol.css";
import {getMunicipalityName} from "../../services/kommuner/kommuner";
import {CommonFilterItem} from "../../contexts/MapContextProvider";
import {isCommonFilterItem, isIThemegroup} from "../Filter/FilterListItem/FilterListItem";
import {EGeoType} from "../../services/enumarations";
import {GeoFaIconService} from "../../services/GeoFaIconService";

const interactionOptions = {
    dragRotate: false
};

interface TheMapProps {
    mapId: string
}

export interface IRouterParams {
    kommuneGUID?: string
    objectId?: string
    locationName?: string   // Named location
}

/*
    Fish the kommuneGUID from the route and retrieve the municipalCodes.
    If it's not there fallback to the first municipalCodes set in the AppConfig
    This is not smart. TODO: Do something about it
 */
export function getKommunenr(kommuneGUID: string | undefined): number {
    if (kommuneGUID) {
        return EXTENDEDMUNICIPALITIES[kommuneGUID].kommunenr;
    } else {
        return AppConfig.municipalCodes[0];
    }
}

/*
    The main map.
 */
const TheMap: React.FC<TheMapProps> = ({mapId}) => {

    let {kommuneGUID} = useParams<IRouterParams>();
    let {locationName} = useParams<IRouterParams>();

    const {t} = useTranslation();

    const iconS = new GeoFaIconService();
    const vdiconS = new VDIconService();

    let xgl: ol.Geolocation;

    const {getState} = useContext(MapContext);

    const ref = useRef(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const [map] = useState(null);

    const [toasterOpen, setToasterOpen] = useState(false);
    const [points, setPoints] = useState<ol.Feature[]>();
    const [VDPoints, setVDPoints] = useState<ol.Feature[]>();

    const [lines, setLines] = useState<ol.Feature[]>();
    const [polygons, setPolygons] = useState<ol.Feature[]>();

    const [loading, setLoading] = useState<boolean>(true);

    const tileLayerOpacity = Number(getComputedStyle(document.documentElement).getPropertyValue('--frilufts-map-tilelayer-opacity'));

    const [initialMeSetup, setInitialMeSetup] =
        useState<boolean>(false);

    // We need this one to distinguish between human move etc.
    // TODO: remove const [moveInitiatedProgrammatically, setMoveInitiatedProgrammatically] =
    //    useState<boolean>(true)

    const [geolocation, setGeolocation] = useState<any>();

    let gl: ol.Geolocation = new ol.Geolocation();

    // WHen the parameter facilityObjectID is set in the facility route
    const routerParams = useParams<IRouterParams>();

    /**
     * Our render function for the point layer.
     */
    const [pointLayer, setPointLayer] = useState(new VectorLayer({
            properties: {
                id: 'points'
            },
            source: new VectorSource(),

            style: function (feature, resolution) {

                // console.log('cncnc', feature.getProperties().temakode);

                if (getState().featureIdInMapOverlay) {

                    if (feature.getProperties().objekt_id === getState().featureIdInMapOverlay) {

                        // Style the marker belonging to the currently shown object in the overlay.
                        // State: Selected
                        // Big in size and z-index to the front.
                        const bigScale = 2.5;
                        const fK = feature.getProperties().facil_ty_k || feature.getProperties().temakode;
                        let s = iconS.getOpenLayersPointStyle(fK, 30 * bigScale, 9000)
                        s.getImage().setOpacity(1);
                        s.getImage().setScale(bigScale);
                        return s;

                    } else {

                        const scale = 0.8;
                        // State: not selected but in
                        const fK = feature.getProperties().facil_ty_k || feature.getProperties().temakode;
                        let s = iconS.getOpenLayersPointStyle(fK, 30 * scale, 10)
                        s.getImage().setOpacity(0.5);
                        // s.getImage().setScale(scale);
                        return s;
                    }
                } else {

                    // console.log('UUU', iconS.getOpenLayersPointStyle(feature.getProperties().facil_ty_k));
                    // The feature is not in the map overlay
                    const fK = feature.getProperties().facil_ty_k || feature.getProperties().temakode;
                    return iconS.getOpenLayersPointStyle(fK, 30, 10);

                }

            },

        })
    );

    /*
    And the render function for the line layer.
     */
    const [lineLayer, setLineLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            properties: {
                id: 'lines'
            },
            style: function (feature, resolution) {
                if (getState().featureIdInMapOverlay) {
                    // if (feature.getProperties().objekt_id === getState().featureInMapOverlay.getProperties().objekt_id) {
                    if (feature.getProperties().objekt_id === getState().featureIdInMapOverlay) {
                        return iconS.getOpenLayersLineStyle(feature.getProperties().rute_ty_k, 3)
                    } else {
                        return iconS.getOpenLayersLineStyle(feature.getProperties().rute_ty_k, 1)
                    }
                } else {
                    // Set
                    return iconS.getOpenLayersLineStyle(feature.getProperties().rute_ty_k, 2);
                }
            },
        })
    );

    const [polygonLayer, setPolygonLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            properties: {
                id: 'polygons'
            },
            style: function (feature, resolution) {
                // if (getState().featureIdInMapOverlay) {
                if (getState().featureIdInMapOverlay) {
                    return iconS.getOpenLayersPolygonStyle(feature.getProperties().facil_ty_k, 3);
                } else {
                    return iconS.getOpenLayersPolygonStyle(feature.getProperties().facil_ty_k, 2);
                }
            }
        })
    );

    const [VDPointLayer, setVDPointLayer] = useState(
        new VectorLayer({
                properties: {
                    id: 'vdpoints'
                },
                source: new VectorSource(),
                style: function (feature, resolution) {
                    const bigScale = 1.5; // 2.5;
                    let s = vdiconS.getOpenLayersPointStyle(feature.getProperties().category, 30 * bigScale, 9000)
                    s.getImage().setOpacity(1);
                    s.getImage().setScale(bigScale);
                    return s;
                },
            },
        )
    );


    // On filter, we push the point features filtered away to the stash layer.
    const [stashedPointLayer, setStashedPointLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            style: new Style(
            )
        })
    );

    // On filter, we push the point features filtered away to the stash layer.
    const [stashedVDPointLayer, setStashedVDPointLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            style: new Style(
            )
        })
    );

    // On filter, we push the line features filtered away to the stash layer.
    const [stashedLineLayer, setStashedLineLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            style: new Style(
            )
        })
    );

    const meNotTrackingStyle = new Style({
        image: new Circle({
            radius: 5,
            fill: new Fill({color: 'blue'}),
            stroke: new Stroke({color: 'white', width: 1}),
        })
    });

    // On filter, we push the line features filtered away to the stash layer.
    const [meLayer, setMeLayer] = useState(new VectorLayer({
            source: new VectorSource(),
            style: new Style({
                image: new Circle({
                    radius: 10,
                    fill: new Fill({color: 'blue'}),
                    stroke: new Stroke({color: 'white', width: 2}),
                }),
            })
        })
    );

    /*
    Flattens a list of filters containing of simple theme numbers and group themes
     */
    function flattenThemeFilter(oL: (IThemegroup | CommonFilterItem)[]): CommonFilterItem[] {

        let result: CommonFilterItem[] = [];

        if (oL) {
            oL.forEach((o: (IThemegroup | CommonFilterItem)) => {
                if (!isCommonFilterItem(o)) {
                    result = result.concat(o.themes)
                }
                if (isCommonFilterItem(o)) {
                    result.push(o)
                }
            })
        }

        return result;
    }

    /*
        Resetting the viewport - from the toaster when we ended up in Africa.
        It compares to the first municipalCodes.
        TODO: Fix this.
     */
    function resetViewport() {

        if (getKommunenr(kommuneGUID) == AppConfig.municipalCodes[0] ! && !AppConfig.map.zoomToExtent) {

            const initialMapCenter = transform(AppConfig.map.initialMapCenter, "EPSG:4326", "EPSG:900913")
            const initialMapZoom = AppConfig.map.initialMapZoom

            const view = new View({
                center: initialMapCenter,
                zoom: initialMapZoom - 3,
                enableRotation: AppConfig.map.enableRotation
            });

            const extent = view.calculateExtent();

            if (extent) {
                getState().mapObject.getView().fit(extent, {
                    padding: [0, 0, 0, 0],
                    duration: 2000
                });
            }

        } else {
            console.log('zooming');
            // TODO: Zoom to extent here.
            zoomToFeaturesInFilter();
        }
    }

    function zoomIn() {
        const view = getState().mapObject.getView()
        const currentZoom = view.getZoom();
        view.setZoom(currentZoom + 1);
    }

    function zoomOut() {
        const view = getState().mapObject.getView()
        const currentZoom = view.getZoom();
        view.setZoom(currentZoom - 1);
    }

    /**
     * Sometimes styling the markers kicks in after a pan - lets do a zero-pan-by-code
     */
    function postStyleRefresh() {
        const mapCenter = getState().mapObject.getView().getCenter();
        getState().mapObject.getView().setCenter(mapCenter);
    }

    /**
     * We listen to changes in the filters and move features that are not in the
     * Theme Filter into the Stash Layer and vice versa.
     * TODO: change later to make them invisible
     */
    useEffect(() => {

            const flattenedThemeFilter = flattenThemeFilter(getState().themeFilter);
            const filteredData = flattenedThemeFilter
                .filter(item => item.source === "geofa")
                .map(item => item.category);

            if (flattenedThemeFilter.length > 0) {

                // Point Features ==> Stash
                pointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.indexOf(feature.getProperties().facil_ty_k) !== 0) {
                        let src = stashedPointLayer && stashedPointLayer?.getSource();
                        if (src) {
                            let nF = new ol.Feature();
                            nF.setGeometry(feature.getGeometry());
                            nF.setProperties(feature.getProperties());
                            pointLayer?.getSource()?.removeFeature(feature);
                            stashedPointLayer?.getSource()?.addFeature(nF);
                        }
                    }
                });

                // Line Features ==> Stash
                lineLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.indexOf(feature.getProperties().rute_ty_k) !== 0) {
                        let src = stashedLineLayer && stashedLineLayer?.getSource();
                        if (src) {
                            let nF = new ol.Feature();
                            nF.setGeometry(feature.getGeometry());
                            nF.setProperties(feature.getProperties());
                            lineLayer?.getSource()?.removeFeature(feature);
                            stashedLineLayer?.getSource()?.addFeature(nF);
                            nF.setStyle(new Style({
                                stroke: new Stroke({
                                    width: 2,
                                    color: 'rgba(204, 100, 50, 0.1)'
                                }),
                            }))
                        }
                    }
                });

                // Moving LINE features from stash to layers

                // Go through line features in the filter and move them back to the line layer
                stashedLineLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.indexOf(feature.getProperties().rute_ty_k) > -1) {
                        let src = lineLayer && stashedLineLayer?.getSource();
                        if (src) {
                            let nF = new ol.Feature();
                            nF.setGeometry(feature.getGeometry());
                            nF.setProperties(feature.getProperties());
                            stashedLineLayer?.getSource()?.removeFeature(feature);
                            lineLayer?.getSource()?.addFeature(nF);
                            // nF.setStyle(iconS.getOpenLayersLineStyle(nF.getProperties().rute_ty_k))
                        }
                    }
                });

                // POINT Features back from stash to point layer
                stashedPointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.indexOf(feature.getProperties().facil_ty_k) > -1) {
                        let nF = new ol.Feature();
                        nF.setGeometry(feature.getGeometry());
                        nF.setProperties(feature.getProperties());
                        stashedPointLayer?.getSource()?.removeFeature(feature);
                        pointLayer?.getSource()?.addFeature(nF);
                        nF.setStyle(iconS.getOpenLayersPointStyle(nF.getProperties().facil_ty_k))

                    }
                });

            } else {
                // No filter set, we move everything back from the POINT stash to the respective layers.
                stashedPointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    let nF = new ol.Feature();
                    nF.setGeometry(feature.getGeometry());
                    nF.setProperties(feature.getProperties());
                    stashedPointLayer?.getSource()?.removeFeature(feature);
                    pointLayer?.getSource()?.addFeature(nF);
                    nF.setStyle(iconS.getOpenLayersPointStyle(nF.getProperties().facil_ty_k))
                });

                // No filter set, we move everything back from the LINE stash to the respective layers.
                stashedLineLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.length === 0) {
                        let src = lineLayer && stashedLineLayer?.getSource();
                        if (src) {
                            let nF = new ol.Feature();
                            nF.setGeometry(feature.getGeometry());
                            nF.setProperties(feature.getProperties());
                            stashedPointLayer?.getSource()?.removeFeature(feature);
                            lineLayer?.getSource()?.addFeature(nF);
                            // nF.setStyle(iconS.getOpenLayersLineStyle(nF.getProperties().rute_ty_k))
                        }
                    }
                });

                // TODO: Add polygon layer
            }

            const view = getState().mapObject.getView();
            const size = getState().mapObject.getSize();

            if (view && size) {

                const extent = getState().mapObject.getView().calculateExtent(
                    getState().mapObject.getSize()
                );

                // Let us populate featuresinviewport
                if (extent) {

                    let features: ol.Feature[] = [];

                    // TODO: Memoize this.
                    // TODO: Performance: better without map()?
                    const layers = [pointLayer, lineLayer, polygonLayer];

                    layers.map((layer) => {
                        layer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
                            features.push(feature);
                        });
                    })

                    getState().setFeaturesInViewport(features)
                }
            }

            //}, [getState, flattenThemeFilter(getState().themeFilter).toString()]
        }, [getState, getState().themeFilter]
    )

    /**
     * VD ONLY !!
     * We listen to changes in the filters and move features that are not in the
     * Theme Filter into the Stash Layer and vice versa.
     * TODO: change later to make them invisible
     */
    useEffect(() => {

        const flattenedVDThemeFilter = flattenThemeFilter(getState().themeFilter);

        const filteredData = flattenedVDThemeFilter
            .filter(item => item.source === "vd")
            .map(item => item.category);

        if (filteredData.length > 0) {

                // Point Features ==> Stash
                VDPointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    console.log('m', (typeof feature.getProperties().category))
                    if (filteredData.indexOf(feature.getProperties().category) !== 0) {
                        let src = stashedVDPointLayer && stashedVDPointLayer?.getSource();
                        console.log('moving')
                        if (src) {
                            let nF = new ol.Feature();
                            nF.setGeometry(feature.getGeometry());
                            nF.setProperties(feature.getProperties());
                            VDPointLayer?.getSource()?.removeFeature(feature);
                            stashedVDPointLayer?.getSource()?.addFeature(nF);
                        }
                    }
                });

                // POINT Features back from stash to point layer
                stashedVDPointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    if (filteredData.indexOf(feature.getProperties().category) > -1) {
                        let nF = new ol.Feature();
                        nF.setGeometry(feature.getGeometry());
                        nF.setProperties(feature.getProperties());
                        stashedVDPointLayer?.getSource()?.removeFeature(feature);
                        VDPointLayer?.getSource()?.addFeature(nF);
                        nF.setStyle(vdiconS.getOpenLayersPointStyle(nF.getProperties().category))

                    }
                });

            } else {
                // No filter set, we move everything back from the VDPOINT stash to the respective layers.
                stashedVDPointLayer.getSource()?.getFeatures()?.forEach((feature: ol.Feature) => {
                    let nF = new ol.Feature();
                    nF.setGeometry(feature.getGeometry());
                    nF.setProperties(feature.getProperties());
                    stashedVDPointLayer?.getSource()?.removeFeature(feature);
                    pointLayer?.getSource()?.addFeature(nF);
                    nF.setStyle(vdiconS.getOpenLayersPointStyle(nF.getProperties().category))
                });
            }

            const view = getState().mapObject.getView();
            const size = getState().mapObject.getSize();

            if (view && size) {

                const extent = getState().mapObject.getView().calculateExtent(
                    getState().mapObject.getSize()
                );

                // Let us populate featuresinviewport
                if (extent) {

                    let features: ol.Feature[] = [];

                    // TODO: Memoize this.
                    // TODO: Performance: better without map()?
                    const layers = [pointLayer, lineLayer, polygonLayer];

                    layers.map((layer) => {
                        layer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
                            features.push(feature);
                        });
                    })

                    getState().setFeaturesInViewport(features)
                }
            }
        }, [getState, flattenThemeFilter(getState().themeFilter).toString()]
    )

    /*
    First thing to do.
     */
    useEffect(() => {

        const GAS = new GeoFAService();
        const VDS = new VDService();

        /**
         * We instantiate a View and set it to the params given in the config file
         * TODO: Zoom-pan to extent
         */
        let v = new ol.View({
            projection: 'EPSG:900913',
            // projection: 'EPSG:25823',
            zoom: AppConfig.map.initialMapZoom,
            center: transform(AppConfig.map.initialMapCenter, "EPSG:4326", "EPSG:900913"),
        });

        let osm = new OSM();

        let control = new Control({
            // TODO: Call this one something sensible. Don't forget the CSS.
            element: document.getElementById('xxx')!,
        });

        const scaleLineControl = new ScaleLine({
            units: 'metric', // or 'imperial'
            bar: true, // Shows a solid bar instead of line
            steps: 4, // Number of scale divisions
            text: true, // Show text labels
            // minWidth: 100,// Minimum width of the scalebar
            minWidth: window.innerWidth > 768 ? 150 : 100
        });

        /*
            The Map definition containing the View. Layers
            will be populated further down after the Map's instantiation.
            The View is per standard defined as EPSG:3857.
         */
        let o = {
            view: v,
            layers: [
                new TileLayer({
                    source: osm,
                    opacity: tileLayerOpacity
                }),
                VDPointLayer,
                stashedPointLayer,
                stashedLineLayer,
                lineLayer,
                pointLayer,
                meLayer
            ],
            controls: defaultControls().extend([scaleLineControl, scaleLineControl]),
            overlays: [],
            attribution: 'Nordiq Group',
            interactions: defaultInteractions({
                pinchRotate: false // Disable rotation
            })
        };

        let mapObject = new ol.Map(o);
        mapObject.setTarget(mapId);
        getState().setMapObject(mapObject);

        mapObject.getView().set('moveInitiatedProgrammatically', true);

        const fetchPointData = async (nr: number) => {

            // Let's loop through the list og municipality codes and pull points and lines for each separately

            // First the chargers
            const elLaderFeatures = await GAS.getElladestandere<ol.Feature[]>(nr);

            const pointFeatures = await GAS.getFeatures<ol.Feature[]>(
                nr,
                EGeoType.Point,
                GAS.MIN_FIELDS_5800
            );

            const elLaderAndPointFeatures = elLaderFeatures.concat(pointFeatures)

            if (pointLayer?.getSource()) {

                // pointLayer?.getSource()?.addFeatures(features)
                pointLayer?.getSource()?.addFeatures(elLaderAndPointFeatures)

                setPoints(elLaderAndPointFeatures);

                // Zoom-pan to the extent, but only if we are not in the municipality defined in the AppCfg
                // console.log(locationName, kommuneGUID, AppConfig.municipalCodes);
                if (locationName) {

                    const ld = LOCATIONS[locationName]
                    // console.log(ld);

                    getState().mapObject.setView(new View({
                        center: ld.center, //fromLonLat([9.5018, 56.2639]),
                        zoom: ld.zoom
                    }))

                } else {
                    if (getKommunenr(kommuneGUID) != AppConfig.municipalCodes[0]) {
                        zoomToFeaturesInFilter(true);
                    }
                }

                updateFeaturesInExtent(mapObject)
                const aF = getState().allFeatures;
                getState().setAllFeatures(elLaderAndPointFeatures.concat(aF));

                console.log(`${elLaderAndPointFeatures.length} POINTS loaded (${getState().allFeatures.length} for ${getMunicipalityName(nr)}).`)

            }

        }

        /*
        Now get the VisitDenmark points-
         */
        const fetchVDPoints = async (municipalityNumber: number) => {
            const features = await VDS.getFeatures<ol.Feature[]>(
                municipalityNumber,
                EGeoType.Point
            );

            console.log(`${features.length} VD points fetched for ${getMunicipalityName(municipalityNumber)}.`);

            if (VDPointLayer?.getSource()) {
                VDPointLayer?.getSource()?.addFeatures(features)
                setVDPoints(features);
                getState().setAllVDFeatures(features);
            }
        }

        let ll: ol.Feature[];
        const fetchLineData = async (municipalityNumber: number) => {

            const features = await GAS.getFeatures<ol.Feature[]>(
                municipalityNumber,
                EGeoType.Line,
                GAS.MIN_FIELDS_5802
            );

            if (lineLayer?.getSource()) {
                lineLayer?.getSource()?.addFeatures(features)
            }

            updateFeaturesInExtent(mapObject)
            getState().setAllFeatures(getState().allFeatures.concat(features));

            console.log(`${features.length} LINES loaded (${getState().allFeatures.length}) in municipality ${getMunicipalityName(municipalityNumber)}.`)
        }

        for (const nr of AppConfig.municipalCodes) {
            fetchPointData(nr).then(r => {
                setPoints(pointLayer?.getSource()?.getFeatures());
            });
            fetchLineData(nr).then(r => {
                setLines(lineLayer?.getSource()?.getFeatures());
            })

            setLoading(false);
        }

        if (AppConfig.visitDenmark) {
            AppConfig.municipalCodes.forEach((nr) => {
                console.log(`FETCH VD: ${nr} of ${getState().allVDFeatures.length}`)
                fetchVDPoints(nr).then(r => {
                    setLoading(false);
                    // setVDPoints(vDP)
                });
            })
        }

        /**
         * After every move / zoom we update the features in viewpoint of the context
         */
        mapObject.on('moveend', handleMapMoveEnd.bind(this));
        mapObject.on('pointermove', handleDragging.bind(this));

        /*
        We have tapped on the map, anywhere.
         */
        mapObject.on('click', function (evt) {

            const features = mapObject.getFeaturesAtPixel(evt.pixel, {
                hitTolerance: AppConfig.map.hitTolerance,
                layerFilter: function (layer) {
                    return ['points', 'lines', 'polygons', 'vdpoints'].includes(layer.get('id'));
                }
            });

            if (features && features.length > 0) {

                // We tapped on features - lucky.
                getState().setTappedFeatures(features);
                getState().setFeatureIdInMapOverlay(
                    features[0]?.getProperties().objekt_id || features[0]?.getProperties().id
                );

            } else {
                // Tap everywhere else than on a feature. We set everything to zero
                getState().setTappedFeatures([]);
                getState().setFeatureIdInMapOverlay(undefined);
                getState().setSelectedObjectId(undefined)
            }
        });

        setTimeout(() => {
            getState().mapObject.updateSize();
        }, 100);

    }, [getState, ref]);

    function toHere() {

        console.log('toHere ', getState().locationStatus);

        // Not when FAB blue
        if (getState().locationStatus !== 2) {
            // console.log('/ Not when FAB blue: ', geolocation)
        }

        if (getState().locationStatus === 3) {
            // Not on the location anymore, lets get back to it
            getState().setLocationStatus(2);
        }

        if (geolocation == undefined) {

            console.log('xgl undefined - setting up xgl with tracking');

            // Spinner: ON!
            getState().setLocationStatus(4)

            setGeolocation(new ol.Geolocation({
                tracking: true
            }));

        } else {
            // console.log('Updating tap location')
            const pos = geolocation.getPosition();
            getState().mapObject.getView().setCenter(fromLonLat(pos));
        }

    }

    const handleGeoLocationError = (m: MapEvent) => {
        // Access denied, set the red man on
        getState().setLocationStatus(1);
    }

    const handleGeoLocationChange = (m: MapEvent) => {

        // Initial setting the tap location
        // console.log('TAP COORD: ', getState().tapCoordinate);

        if (!getState().tapCoordinate) {
            // console.log('asasasasas', geolocation.getPosition())
            getState().setTapCoordinate(geolocation.getPosition())

        } else {

            const line = new LineString([
                fromLonLat(getState().tapCoordinate),
                fromLonLat(geolocation.getPosition())
            ]);

            const distance = getLength(line);
            if (distance > AppConfig.distanceTappedLocation) {
                // We are away from the tapped location, lets switch to white
                getState().setLocationStatus(3);
            }
            console.log('DIST in m: ', Math.round(distance * 100) / 100 + ' ' + 'm')
        }

        // console.log('change')

        const pos = geolocation.getPosition();

        if (meLayer.getSource()?.getFeatures().length === 0 && pos) {

            // meLayer still empty, lets add a feature

            meLayer.getSource()?.addFeature(new ol.Feature({
                //geometry: new Point(fromLonLat(transform(tapCoordinate, "EPSG:4326", "EPSG:900913")))
                geometry: new Point(fromLonLat(pos))
            }))

            getState().mapObject.getView().setCenter(fromLonLat(pos));

        } else {

            // move the me point to the right location
            const geometry = meLayer.getSource()?.getFeatures()[0]?.getGeometry() as Point;

            if (geometry) {
                const p = geolocation.getPosition();
                if (p) {
                    let loc = transform(p, "EPSG:4326", "EPSG:900913")
                    geometry.setCoordinates(loc || []);
                }
            }
        }

    };

    useEffect(() => {

        if (geolocation) {

            console.log('Geolocation service is running');
            geolocation?.on('change', handleGeoLocationChange.bind(this))
            geolocation?.on('error', handleGeoLocationError.bind(this))

        }

    }, [geolocation]);

    useEffect(() => {

        if (getState().tapCoordinate) {

            // console.log('TAP COORDINATE CHANGED: ', getState().tapCoordinate);

            // We set the state to blue
            getState().setLocationStatus(2);
            // console.log(meLayer.getSource()?.getFeatures());

            // const tTc = fromLonLat(transform(tapCoordinate, "EPSG:4326", "EPSG:900913"))

            // TODO: Smooth transition
            // getState().mapObject.getView().setCenter(tTc);

            if (meLayer.getSource()?.getFeatures().length === 0 && getState().tapCoordinate) {

                // meLayer still empty, lets add a feature

                meLayer.getSource()?.addFeature(new ol.Feature({
                    //geometry: new Point(fromLonLat(transform(tapCoordinate, "EPSG:4326", "EPSG:900913")))
                    geometry: new Point(fromLonLat(getState().tapCoordinate))
                }))
                // const transformedLoc = fromLonLat(transform(loc, "EPSG:4326", "EPSG:900913"));
                //console.log('x', loc);
                console.log('->', meLayer.getSource()?.getFeatures());
                getState().mapObject.getView().setCenter(fromLonLat(getState().tapCoordinate));
            } else {
                getState().mapObject.getView().setCenter(fromLonLat(getState().tapCoordinate));
                getState().setLocationStatus(2);
            }

        }

    }, [getState().tapCoordinate]);

    useEffect(() => {
        if (getState().allFeatures.length > 0) {
            // Access the icon element and set the class name after the component has mounted
            const iconElements = document.getElementsByClassName('ol-icon');
            Array.from(iconElements).forEach((element) => {
                element.classList.add(styles.markerShadow); // Add your desired class name
            });
        }
        showFacilityFromRouter();
    }, [getState, getState().allFeatures]);

    /**
     * If we call
     *
     * <Route exact path="/facility/:objectId" component={MapTab}></Route>
     *
     */
    function showFacilityFromRouter() {
        // Check whether we have a facility set in the router ---
        if (routerParams.objectId) {
            // ---
            const facilityId = routerParams.objectId;
            console.log('!!!!', facilityId, points);
            let filteredPoints = points?.filter(p =>
                p.getProperties().objekt_id === routerParams.objectId);
            if (filteredPoints?.length === 1) {
                console.log(filteredPoints);
                getState().setTappedFeatures(filteredPoints);
                getState().setFeatureIdInMapOverlay(facilityId)

                getState().setFeatureIdInMapOverlay(
                    filteredPoints[0]?.getProperties().objekt_id || undefined
                );

                const extent = filteredPoints[0]?.getGeometry()?.getExtent();
                if (extent) {
                    const centerX = (extent[0] + extent[2]) / 2;
                    const centerY = (extent[1] + extent[3]) / 2;
                    console.log("Center coordinates of the extent:", [centerX, centerY]);
                    getState().mapObject.setView(new View({
                        center: [centerX, centerY],
                        zoom: 12
                    }))
                }

            }
        }
    }

    /*
    Changes in the tapped feature -> lets re-render all geometries.
     */
    useEffect(() => {
        // console.log('PSR: ', getState().tappedFeatures);
        if (getState().tappedFeatures && getState().tappedFeatures.length === 0) {
            pointLayer?.getSource()?.getFeatures().map((f: ol.Feature) => f.changed());
            lineLayer?.getSource()?.getFeatures().map((f: ol.Feature) => f.changed());
        }
        // postStyleRefresh();
    }, [getState, getState().tappedFeatures])

    /**
     * On change in the feature in map overlay - for example after swipe
     */
    useEffect(() => {
        if (getState().featureIdInMapOverlay) {
            pointLayer?.getSource()?.getFeatures().map((f: ol.Feature) => f.changed());
            postStyleRefresh();
        }
    }, [getState, getState().featureIdInMapOverlay, pointLayer])

    /**
     * On change in the feature in map overlay - for example after swipe
     */
    useEffect(() => {
        lineLayer?.getSource()?.getFeatures().map((f: ol.Feature) => f.changed());
    }, [getState, getState().featureIdInMapOverlay, lineLayer])

    /**
     * On change in the feature in map overlay - for example after swipe
     */
    useEffect(() => {
        if (getState().featuresInViewport.length === 0 && !loading) {
            setToasterOpen(true);
        } else {
            setToasterOpen(false);
        }
    }, [getState, getState().featuresInViewport])

    const handleDragging = (evt: any) => {
        if (evt.dragging) {

            // user has dragged the map away from the center position
            getState().setLocationStatus(3);
        }
    };

    const handleMapMoveEnd = (m: MapEvent) => {

        let extent = m.map.getView().calculateExtent(m.map.getSize());
        let l: ol.Feature[] = [];

        pointLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            l.push(feature);
        });

        lineLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            l.push(feature);
        });

        polygonLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            l.push(feature);
        });

        getState().setFeaturesInViewport(l);

    }

    const updateFeaturesInExtent = (map: ol.Map) => {

        const extent = map.getView().calculateExtent(map.getSize());

        let features: ol.Feature[] = [];

        pointLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            features.push(feature);
        });

        lineLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            features.push(feature);
        });

        polygonLayer.getSource()?.forEachFeatureInExtent(extent, function (feature) {
            features.push(feature);
        });

        getState().setFeaturesInViewport(features);

    }

    /*
    If no facilities inside viewport, get an option to zoom-pan to the content.
     */
    function zoomToFeaturesInFilter(fromDenmark?: boolean) {

        if (pointLayer?.getSource()?.getFeatures().length === 1) {

            let feature = pointLayer?.getSource()?.getFeatures()[0]
            let geometry = feature?.getGeometry();
            if (geometry instanceof MultiPoint) {

                getState().setShowFilterModal(false)

                const simpleGeometry: SimpleGeometry = (geometry as SimpleGeometry);
                const originalExtent = simpleGeometry.getExtent();
                const center = getCenter(originalExtent);
                const size = getSize(originalExtent);
                const buffer = 3000;
                const newExtent = [center[0] - size[0] / 2 - buffer,
                    center[1] - size[1] / 2 - buffer,
                    center[0] + size[0] / 2 + buffer,
                    center[1] + size[1] / 2 + buffer,
                ];

                getState().mapObject.getView().fit(newExtent, {
                    duration: 200
                });

            }

        } else {

            const extent = pointLayer?.getSource()?.getExtent()

            if (fromDenmark) {
                console.log('zooming from DK');
                getState().mapObject.setView(new View({
                    center: fromLonLat([9.5018, 56.2639]),
                    zoom: 7
                }))
            } else {
                console.log('not zooming from DK')
            }

            if (extent) {
                getState().setShowFilterModal(false)
                getState().mapObject.getView().fit(extent, {
                    padding: [50, 50, 50, 50],
                    duration: 2000
                });
            }
        }
    }

    function toasterButtons() {

        const b1 =
            {
                text: 'Find dem.',
                role: 'info',
                handler: () => {
                    getState().setShowFilterModal(false);
                    zoomToFeaturesInFilter()
                },
            }

        let butts = [b1]

        if (getState().themeFilter.length > 0) {
            butts.push(
                {
                    text: 'Nulstil filter',
                    role: 'cancel',
                    handler: () => {
                        getState().setThemeFilter([]);
                        getState().setVDThemeFilter([]);
                    },
                }
            )
            butts.push(
                {
                    text: 'Luk',
                    role: 'close',
                    handler: () => {
                        console.log('CLOSE TODO')
                    },
                }
            )

        }

        return butts;
    }

    function locationFabColor(locationStatus: number): string {

        interface Dictionary {
            [key: number]: string;
        }

        const dd: Dictionary = {
            0: styles.zero,             // Initial state, grey, on app boot
            1: styles.one,              // Error - user has pressed "do not use location service
            2: styles.two,              // FAB bg is blue - we are in the location of the GPS, both in nature as well as on the map
            3: styles.three,            // FAB white - we are not in the location
            4: styles.four,             // Spinning
        };
        return dd[locationStatus];
    }

    return (<>
            <IonFab slot="fixed" vertical="top" horizontal="start">
                <IonFabButton size="small" id="click-trigger" onClick={toHere}
                              className={locationFabColor(getState().locationStatus)}>
                    {getState().locationStatus === 1 ?
                        <IonIcon icon={accessibilityOutline}></IonIcon>
                        : null}
                    {getState().locationStatus === 0 ?
                        <IonIcon icon={hereSVG}></IonIcon> : null}
                    {getState().locationStatus === 2 ?
                        <IonIcon icon={hereSVG}></IonIcon> : null}
                    {getState().locationStatus === 3 ?
                        <IonIcon icon={hereSVG}></IonIcon> : null}
                    {getState().locationStatus === 4 ?
                        <IonSpinner name='circles'></IonSpinner> : null}
                </IonFabButton>
                <IonFabButton size="small" onClick={zoomIn} className={styles.hideOnSmall}>
                    <IonIcon icon={addCircleOutline}></IonIcon>
                </IonFabButton>
                <IonFabButton size="small" onClick={zoomOut} className={styles.hideOnSmall}>
                    <IonIcon icon={removeCircleOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
            {loading &&
                <>
                    <IonBackdrop visible={loading}></IonBackdrop>
                    <div className={styles.box}>
                        Henter data &nbsp;<IonSpinner name="dots"></IonSpinner>
                    </div>
                </>
            }

            <IonToast message={t('noFacilities')}
                      position="top"
                      duration={2000}
                      cssClass={styles.mapToaster}
                      isOpen={toasterOpen}
                      icon={warningOutline}
                      buttons={toasterButtons()}>
            </IonToast>

            <IonContent className={styles.background}>
                <div className={styles.mapContainer}>
                    <MapContext.Provider value={map!}>
                        <div ref={mapRef}
                             className={styles.olmap}
                             id={mapId}>
                        </div>
                    </MapContext.Provider>
                    <div id={"xxx"}>
                    </div>
                </div>
            </IonContent>
        </>
    );
};

export default TheMap;
