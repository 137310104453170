import MUNICIPALITIES from "../Municipalities";

const collator = new Intl.Collator('da-DK', { numeric: true });
const municipalities = Object.entries(MUNICIPALITIES)
    .map(([code, name]) => ({ code, name }))
    .sort((a, b) => collator.compare(a.name, b.name));

export function getMunicipalityName(kommunenr: number):string {
    return MUNICIPALITIES[kommunenr] || 'Månen'
}

