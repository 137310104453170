import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonToolbar} from '@ionic/react';
import TheMap from "../components/TheMap/TheMap";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import MapContext from "../contexts/MapContext";

import styles from "./MapTab.module.css";
import Header from "../components/Header/Header";
import {arrowDownOutline, arrowUpOutline} from "ionicons/icons";
import {AppConfig} from "../utils/AppConfig";
import FacilityBadge from "../components/MapOverlay/FacilityBadge";
import {Trans} from "react-i18next";
import {Feature} from "ol";


const MapTab: React.FC = () => {

    const modal = useRef<HTMLIonModalElement>(null);

    const [modalContent, setModalContent] =
        useState<JSX.Element | null>(null);

    const {getState} = useContext(MapContext);

    function modalFullyOpened(): boolean {
        modal?.current?.getCurrentBreakpoint().then((bp) => {
            return bp === 1.0
        })
        return false;
    }

    function smC() {
        setModalContent(
            <>
                <IonHeader className={styles.header}>
                    <IonToolbar className={styles.toolbar}>
                        {(modalFullyOpened()) &&
                            <IonButtons slot="start">
                                <IonButton onClick={handleMaxModal}>
                                    <IonIcon icon={arrowUpOutline}></IonIcon>
                                    OP
                                </IonButton>
                            </IonButtons>
                        }
                        <IonButtons slot="start">
                            <IonButton onClick={share}>DEL</IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton onClick={handleCloseModal}>
                                <IonIcon icon={arrowDownOutline}></IonIcon>
                                <Trans i18nKey={'close'}></Trans>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className={styles.flexWrapper}>
                        <FacilityBadge></FacilityBadge>
                    </div>
                </IonContent>
            </>
        )
    }

    useEffect(() => {

        if (getState().tappedFeatures.length > 0) {
            smC()
            getState().setShowFilterModal(true);
        } else {
            getState().setShowFilterModal(false);
        }

    }, [getState, getState().tappedFeatures])

    function onDismiss(x: any) {
        getState().setTappedFeatures([]);
        getState().setFeatureIdInMapOverlay(undefined);
        getState().setSelectedObjectId(undefined);
        getState().setShowFilterModal(false)
    }

    async function share() {

        const features: Feature[] = getState().allFeatures;
        let filteredFeature = features.filter(
            o => o.getProperties().objekt_id === getState().featureIdInMapOverlay
        )

        let ftr;

        let navn = '';
        if (filteredFeature.length > 0) {
            ftr = filteredFeature[0]
            const props = ftr.getProperties();
            navn = props.navn
            console.log(navn);
        }

        let properties = ftr?.getProperties() || {}

        const url = AppConfig.appBaseUrl + '/facility/' + getState().featureIdInMapOverlay;
        console.log(url);

        navigator.share({
            title: navn,
            text: navn,
            url: url
        });
    }

    function handleCloseModal() {
        getState().setShowFilterModal(false)
        getState().setTappedFeatures([]);
    }

    function handleMaxModal() {
        modal?.current?.setCurrentBreakpoint(1);
    }

    return (
        <div className={styles.iphoneWrapper}>
            <IonPage className={styles.ionPage}>

                <Header></Header>

                <IonContent>
                    <TheMap mapId={'map'}></TheMap>
                </IonContent>

                <IonModal
                    animated={true}
                    mode={'md'}
                    ref={modal}
                    className={styles.mapModal}
                    handleBehavior="cycle"
                    keepContentsMounted={true}
                    isOpen={getState().showFilterModal}
                    onDidDismiss={(swiper) => onDismiss(swiper)}
                    initialBreakpoint={AppConfig.modals.mapClick.initialBreakPoint}
                    backdropBreakpoint={0.4}
                    breakpoints={AppConfig.modals.mapClick.breakpoints}>
                    {modalContent}
                </IonModal>
            </IonPage>
        </div>
    );
};

export default MapTab;
