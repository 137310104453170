// TODO: Rename to feature type
export enum EGeoType {
    Point = 't_5800_fac_pkt',
    Line = 't_5802_fac_li',
    Polygon = 't_5801_fac_fl',
    VDPoint = 'vd'
}

export enum ESource {
    vd = 'vd',
    geofa = 'geofa'
}

// The field we filter on
// Choose from beliggenhedskommune eller kommune
export enum EFilterName {
    beliggenhedskommune,
    kommune
}
