import {IThemegroup} from "../../../interfaces/ICategoryItem";
import {CommonFilterItem} from "../../../contexts/MapContextProvider";
import styles from './FilterListItem.module.css';
import {useEffect, useState} from "react";
import FacilityIcon from "../../FacilityIcon/FacilityIcon";
import {VDIconService} from "../../../services/VDIconService";
import {GeoFaIconService} from "../../../services/GeoFaIconService";
import {IBaseTheme} from "../../../services/interfaces";
import i18next from "i18next";

interface FilterListItemProps {
    category: (IThemegroup | CommonFilterItem)
    onClick?: () => void;
    source?: string
}

// TODO: put into helpers.ts 2025
export function isCommonFilterItem(obj: CommonFilterItem | IThemegroup): obj is CommonFilterItem {
    return (obj as CommonFilterItem).source !== undefined;
}

// TODO: put into helpers.ts 2025
export function isIThemegroup(obj: CommonFilterItem | IThemegroup): obj is IThemegroup {
    return (obj as IThemegroup).pictogram !== undefined;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
                                                           category,
                                                           source,
                                                           onClick
}) => {

    const [label, setLabel] = useState<string>();
    const [themes, setThemes] = useState<CommonFilterItem[]>([]);

    const iconService = new GeoFaIconService();
    const vDIconService = new VDIconService();

    // TODO: 2025: fix this
    let gT;
    const staticClassName = 'line';

    const dynamicClassName = `geofa-line-${gT}`;
    const combinedClassName = `${staticClassName} ${dynamicClassName}`;

    function getIcons() {
        return themes.map((theme, index) =>
            <FacilityIcon key={index} itemClass={theme} fontSize={"25px"}></FacilityIcon>)
    }

    function getSimpleLangCode(): string {
        const fullLangCode = i18next.language; // e.g., "en-US", "de-DE", "fr-CA"
        return fullLangCode.split('-')[0]; // Extracts "en", "de", "fr"
    }

    /**
     * Set label by language.
     */
    function getLabel(theme: IBaseTheme | undefined): string {

        console.log('theme', theme);

        const lng = getSimpleLangCode();

        if (theme) {
            if (lng === 'da') {
                return theme.label || 'No Theme'
            } else if (lng === 'de') {
                return theme.label_de || theme.label
            } else {
                return theme.label_gb || theme.label
            }
        } else {
            return '<>';
        }
    }

    useEffect(() => {

        let thms: CommonFilterItem[] = [];

        if (isCommonFilterItem(category)) {
            thms.push(category);
            console.log('cc', category);
            setLabel(getLabel(iconService.getTheme(category, category.type)));

        } else {
            // GROUP FILTER

            const lng = getSimpleLangCode() as 'da' | 'de' | 'default';
            const labelMapping: { da: string; de: string; default: string } = {
                da: category.label || 'No Theme',
                de: category.label_de || category.label,
                default: category.label_gb || category.label
            };

            setLabel(labelMapping[lng]);

            // TODO: fork for VD

            let ll: any[] = category.themes.map((num) => ({
                theme: iconService.getTheme(num),
                type: iconService.getGeotype(num),
                category: num.category
            }));

            thms = ll;
        }

        setThemes(thms);

    }, []);

    return (
        <div className={styles.container}>
            <span className={combinedClassName}></span>
            <>{label}</>
            <div className={styles.top}>
                {getIcons()}
            </div>
        </div>
    )

};

export default FilterListItem;
