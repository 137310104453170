import * as React from "react";
import {ImgHTMLAttributes, useContext, useEffect, useState} from "react";

import {Swiper, SwiperSlide} from 'swiper/react';
import GeoFAService from "../../../services/GeoFAService";

import * as ol from "ol";
import {Feature} from "ol";

import styles from "./PhotoCarousel.module.css";
import MapContext from "../../../contexts/MapContext";
import {getKommunenr, IRouterParams} from "../../TheMap/TheMap";
import {useParams} from "react-router-dom";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    srcset: string;
}

interface ResponsiveImageProps {
    fotoLokat: string;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
    fotoLokat
                                                         }) => {

    // https://crystallize.com/blog/react-srcset-for-responsive-images
    // 171, 360, 560 og 1600
    // <img src={gAs.PHOTO_URL + feature?.getProperties().foto_lokat + '.jpg'}

    let { kommuneGUID } = useParams<IRouterParams>();

    const gAs = new GeoFAService();
    const BASE_URL = gAs.PHOTO_URL;
    const extension = '.jpg';

    const u = BASE_URL + fotoLokat + extension;
    const u171 = BASE_URL + '171/' + fotoLokat + extension;
    const u360 = BASE_URL + '360/' + fotoLokat + extension;
    const u560 = BASE_URL + '560/' + fotoLokat + extension;
    const u1600 = BASE_URL + '1600/' + fotoLokat + extension;

    const srcSet =
        u171 + ' 171w, ' +
        u360 + ' 360w, ' +
        u560 + ' 560w, ' +
        u1600 + ' 1600w';

    const sizes =
        '(max-width: 171px) 171px, ' +
        '(max-width: 360px) 360px, ' +
        '(max-width: 560px) 560px, ' +
        '1600px';

    function handleLoad() {
    }

    return (
        <img
            alt="123"
            srcSet={srcSet}
            sizes={sizes}
            onLoad={handleLoad}
        />
    )

}

interface PhotoCarouselProps {
    objectId: string,
    properties:  MinimalPhotoProperties
}

interface MinimalPhotoProperties {
    geofafoto: string,
    geofafoto1: string,
    geofafoto2: string,
    geofafoto3: string,
    foto_link: string,
    foto_link1: string,
    foto_link2: string,
    foto_link3: string
}

interface InternalSwiperSlideWrapperProps {
    feature: Feature
}

const InternalSwiperSlideWrapper: React.FC<InternalSwiperSlideWrapperProps> = ({
                                                                                   feature
                                                                               }) => {

    let { kommuneGUID } = useParams<IRouterParams>();

    const gAs = new GeoFAService();

    const {getState} = useContext(MapContext);

    const [loadable, setLoadable] = useState(true);

    function handleLoad() {
        setLoadable(true);
        // getState().setOverlapSwiperVisibleSlideEventCounter(getState().overlapSwiperVisibleSlideEventCounter+1);
    }

    function handleError() {
        setLoadable(false);
    }

    return (<> {loadable &&

            <ResponsiveImage fotoLokat={feature?.getProperties().foto_lokat}></ResponsiveImage>}

        {/*
            <img src={gAs.PHOTO_URL + feature?.getProperties().foto_lokat + '.jpg'}
                 loading={"lazy"}
                 onLoad={handleLoad}
                 onError={handleError}
                 alt={feature.getProperties().foto_navn}>
            </img>
            */}
        </>
    )

}

interface SimpleInternalSwiperSlideWrapperProps {
    url: string;
}


const SimpleInternalSwiperSlideWrapper: React.FC<SimpleInternalSwiperSlideWrapperProps> =({
    url
}) => {

    let loadable = false

    function handleLoad() {
    }

    function handleError() {
    }

    return (<> {loadable &&
            <img src={url}
                 loading={"lazy"}
                 onLoad={handleLoad}
                 onError={handleError}
                 alt={'no alt'}>
            </img>
        }
        </>
    )

};

const PhotoCarousel: React.FC<PhotoCarouselProps> = ({
                                                         objectId,
                                                         properties}) => {

    const {getState} = useContext(MapContext);

    const [photos, setPhotos] = useState<Feature[]>([])
    const [fieldUrls, setFieldUrls] = useState<string[]>([])

    let { kommuneGUID } = useParams<IRouterParams>();

    useEffect(() => {

        const gAs = new GeoFAService();

        async function fetchPhotos() {

            const features: Feature[] = await gAs.getPhotosForFeature(objectId);

            const filteredPrimaerFeatures =
                features.filter(dict => dict.getProperties().primaer_kode === 1);

            const filteredFeatures =
                features.filter(dict => dict.getProperties().primaer_kode !== 1);

            setPhotos([...filteredPrimaerFeatures, ...filteredFeatures] as ol.Feature[]);
        }

        if (objectId) {
            fetchPhotos().then(() => {
            });
        }

    }, [objectId]);

    useEffect(() => {
        if (Object.keys(properties).length !== 0) {
            buildFieldUrls(properties);
        }

    }, [properties]);

    /*
    useEffect(() => {

        if (getState().allFeatures.length>0) {

            console.log('objectId: ', objectId)

            const features = getState().allFeatures.filter((feature: ol.Feature) =>
                feature.getProperties().objekt_id === objectId);

            if (features.length === 1) {
                setFeature(features[0])

            }
        }

    }, [objectId, getState().allFeatures]);
     */

    const [breakpoints] = useState({
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 640px
        790: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 640px
        940: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 640px
        1090: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    });


    const buildFieldUrls = ( properties: MinimalPhotoProperties) => {

        let urls:string[] = [];

        if (properties.geofafoto) {
            urls.push(properties.geofafoto)
        }

        if (properties.geofafoto1) {
            urls.push(properties.geofafoto1)
        }

        if (properties.geofafoto2) {
            urls.push(properties.geofafoto2)
        }

        if (properties.geofafoto3) {
            urls.push(properties.geofafoto3)
        }

        if (properties.foto_link) {
            urls.push(properties.foto_link)
        }

        if (properties.foto_link1) {
            urls.push(properties.foto_link1)
        }

        if (properties.foto_link2) {
            urls.push(properties.foto_link2)
        }

        if (properties.foto_link3) {
            urls.push(properties.foto_link3)
        }

        setFieldUrls(urls);

        // console.log('buildFieldUrls: ', urls);

    }

    const renderFieldSlides = () => {

        // console.log('fieldSlides:', fieldUrls);

        return fieldUrls.map((url) =>
            <SwiperSlide className="list-item" key={url}>
                <img src={url}
                     loading={"lazy"}
                     alt={'no alt'}>
                </img>
            </SwiperSlide>
        );
    }

    const renderSlides = (pp:Feature[]) => {
        // console.log('pp: ', pp);
        return pp.map((f) =>
            <SwiperSlide className="list-item" key={f.getProperties().objekt_id}>
                <InternalSwiperSlideWrapper feature={f}></InternalSwiperSlideWrapper>
            </SwiperSlide>
        );
    }

    const _renderSlides = () => {
        const photosFromFotoforbindelse = photos.map((f) =>
            <SwiperSlide className="list-item" key={'x-' + f.getProperties().objekt_id}>
                <InternalSwiperSlideWrapper feature={f}></InternalSwiperSlideWrapper>
            </SwiperSlide>
        );

        return photosFromFotoforbindelse;
    }

    function handleSlideChange(x: any) {
        // TODO: remove ?
    }

    // {/* <div className={styles.swiperContainer}> */}

    function swiperOrPlaceholder() {

        let slidesPerView = 1.1;
        let spaceBetween = "10px";
        if (photos.length === 1) {
            slidesPerView = 1;
            spaceBetween="0"
        }

        if (photos && photos.length || fieldUrls && fieldUrls.length>0) {

            // console.log('phtotot:', photos);

            return <>
                <Swiper
                    nested={true}
                    autoHeight={true}
                    watchSlidesProgress={true}
                    spaceBetween={spaceBetween}
                    slidesPerView={slidesPerView}
                    className={styles.swiper}
                    onSlideChange={handleSlideChange}
                >
                    {renderSlides(photos)}
                    {renderFieldSlides()}
                </Swiper>
            </>

        } else {
            // TODO: Into config file.
            return <></>
        }
    }

    return (
        <>
            {swiperOrPlaceholder()}
        </>
    )

}

export default PhotoCarousel;
