import * as React from "react";
import IGeoFAGeneral from "../../services/interfaces/IGeoFAGeneral";

import styles from './GeoFaWidget.module.css';
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

interface GeoFaWidgetProps {
    properties: IGeoFAGeneral
    typeCodes: number[]
    field: string
    de_field?: string
    en_field?: string
    label?: string
    dangerous?: boolean

    // If set, we translate the values, typical for fields with a limited set of results.
    translateValue?: boolean
}

const GeoFaWidget: React.FC<GeoFaWidgetProps> = ({
                                                     properties,
                                                     typeCodes,
                                                     label,
                                                     field,
                                                     de_field,
                                                     en_field,
                                                     dangerous,
                                                     translateValue
                                                 }) => {

    const { t } = useTranslation();

    // Set VD temacode to 6666
    let temakode = properties.temakode || 6666;
    let v:any;

    if (de_field || en_field) {

        if (field === 'beskrivels' && ['de', 'de-DE', 'de-CH', 'de-AT', 'de-LI', 'de-LU'].includes(i18n.language)) {
            field = 'd_k_beskr'
        }

        if (field === 'beskrivels' && ['en', 'en-GB', 'en-US'].includes(i18n.language)) {
            field = 'uk_k_beskr';
        }

        if (field === 'lang_beskr' && ['de', 'de-DE', 'de-CH', 'de-AT', 'de-LI', 'de-LU'].includes(i18n.language)) {
            field = 'd_l_beskr'
        }

        if (field === 'lang_beskr' && ['en', 'en-GB', 'en-US'].includes(i18n.language)) {
            field = 'uk_l_beskr';
        }

    }

    // Line
    if (temakode === 5802) {
        if (typeCodes.indexOf(properties.rute_ty_k)) {
            v = properties[field as keyof IGeoFAGeneral];
        }
    }

    // Point
    if (temakode === 5800) {
        if (typeCodes.indexOf(properties.rute_ty_k)) {
            v = properties[field as keyof IGeoFAGeneral];
        }
    }

    // Point from VisitDenmark
    if (temakode === 6666) {
        v = properties[field as keyof IGeoFAGeneral];
    }

    function loading() {
        if (!field) {
            return styles.widget
        }
    }

    if (translateValue) {
        v = t(v.toLowerCase());
    }

    /*
    useEffect(() => {
        console.log('AAAAA ', field)
        console.log('XXXXXX ', properties)
    }, [])
     */

    return (<div className={loading()} id={field}>

            {label && <span className={styles.label}>{label}</span>}
            { dangerous &&
                    <div className={styles.dangerousContent} dangerouslySetInnerHTML={{ __html: v }} ></div>}
            { !dangerous &&
                <div className={styles.content}>
                    {v && v}
                </div>
        }

        </div>
    )
}

export default GeoFaWidget;
