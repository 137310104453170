import * as React from "react";
import {useContext, useEffect, useState} from "react";
import MapContext from "../../../contexts/MapContext";
import {Feature} from "ol";
import {render} from "@testing-library/react";

interface VDPhotoCarouselProps {
    objectId: string,
}

interface IFile {
    Uri: string,
    Name: string
}

const VDPhotoCarousel: React.FC<VDPhotoCarouselProps> = ({
                                                             objectId
                                                         }) => {
    const {getState} = useContext(MapContext);
    const [files, setFiles] = useState<IFile[]>([])

    useEffect(() => {
        const features: Feature[] = getState().allVDFeatures;
        let filteredFeature = features.filter(
            o => o.getId() === objectId
        )
        if (filteredFeature.length) {
            console.log('FF', filteredFeature[0].getProperties().files);
            setFiles(JSON.parse(filteredFeature[0].getProperties().files));
        }
    }, [objectId]);

    function renderFiles(ff:IFile[]) {
        return ff.map((f) => {
            <><img src={f.Uri}/></>
        })
    }

    return (
        <>
            {renderFiles(files)}
        </>
    )
};

export default VDPhotoCarousel;

