import React, {useEffect, useMemo, useState} from 'react';
import {IonIcon, IonSpinner} from '@ionic/react';

import styles from './FacilityIcon.module.css';
import {VDIconService} from "../../services/VDIconService";
import {CommonFilterItem} from "../../contexts/MapContextProvider";
import {GeoFaIconService} from "../../services/GeoFaIconService";
import {EGeoType, ESource} from "../../services/enumarations";

let iconS = new GeoFaIconService();
let vDiconS = new VDIconService();

interface FacilityIconProps {
    itemClass: CommonFilterItem
    fontSize?: string,
    className?: any,
    id?: any,
    loading?: boolean | undefined
}

/*
Starts with showing a spinner and then when loaded the icon of the facility type
 */
const FacilityIcon: React.FC<FacilityIconProps> = ({
                                                       itemClass,
                                                       fontSize,
                                                       loading
                                                   }) => {

    const [l, setL] = useState<boolean | undefined>(true)

    const fs = fontSize || '120px';

    const getSvgSrcMemoized = useMemo(() => {
        // console.log('ITEMCLASS: ', itemClass);
        if (itemClass.source == ESource.vd) {
            return vDiconS.getSvgSrc(itemClass);
        } else {
            return iconS.getSvgSrc(itemClass);
        }
    }, [itemClass]);

    const getSvgPictogramColorMemoized = useMemo(() => {
        let m = null;
        if (itemClass.type == EGeoType.VDPoint) {
            m = vDiconS.getSvgColor(itemClass);
        } else {
            m = iconS.getSvgColor(itemClass);
        }
        return m;
    }, [itemClass]);

    useEffect(() => {
        if (loading)
            setL(loading);
    }, [loading]);

    function getColor():string {
        return getSvgPictogramColorMemoized || 'lightgray';
    }

    return (
        <>
            {loading ? <div className={styles.spinnerContainer}
                            style={{ backgroundColor: getColor()}}>
                <IonSpinner name='circles'
                            color="light"
                            className={styles.ionSpinner}>
                </IonSpinner></div> : false }
            {!loading ? <IonIcon src={getSvgSrcMemoized} style={{fontSize: fs}}/> : false}
        </>
    )
};

export default FacilityIcon;
