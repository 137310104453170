import {Feature} from "ol";
import {Geometry} from "ol/geom";
import {GeoJSON} from "ol/format";
import {AppConfig} from "../utils/AppConfig";
import {EGeoType} from "./enumarations";

export default class VDService {

    constructor() {}

    public async getFeatures<TResponse>(municipalityNumber: number,
                                        type?: EGeoType,
                                        fields?: string[]): Promise<TResponse> {

        // TODO: !!!!!!
        let queryString = AppConfig.visitDenmarkWFSRequest;
        console.log(`WFS: ${queryString}`);
        if (queryString) {

            return fetch(queryString)
                .then((resp) => resp.json())
                .then((data) => {

                    // Doing this by a query did not work, so we filter old school
                    const rawFeatures: Feature<Geometry>[] = new GeoJSON().readFeatures(data, {
                        featureProjection: 'EPSG:3857'
                    });
                    const ftrs: Feature<Geometry>[] = []
                    rawFeatures.forEach((f) => {
                        f.set('source', 'vd');
                        f.set('objekt_id', f.getId());
                        ftrs.push(f);
                    });

                    return ftrs as TResponse;
                })
                .catch(e => {
                    return Promise.reject({
                        status: e.status,
                        ok: false,
                        message: e.json,
                        body: e.json
                    });
                });
        } else {
            return Promise.reject({
                status: 404,
                ok: false,
                message: "VD not set up in config",
                body: {}
            });
        }

    }
}
