import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import * as ol from "ol";
import {Feature} from "ol";
import {OSM} from "ol/source";
import {ScaleLine} from "ol/control";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {MultiLineString, MultiPoint, SimpleGeometry} from "ol/geom";
import {defaults} from "ol/interaction";

import {GeoFaIconService} from "../../../services/GeoFaIconService";
import {AppConfig} from "../../../utils/AppConfig";
import {transform} from "ol/proj";
import {useSwiperSlide} from "swiper/react";

import styles from "./FacilityMap.module.css";
import MapContext from "../../../contexts/MapContext";
import FacilityMapContext from "./FacilityMapContext";

import Extent from 'ol/interaction/Extent.js';

interface DetailMapProps {
    objectId: string
}

/**
 * DetailPage Map showing the selected Facility (point or line, later polygon) and everything around it.
 * The Map is static and cannot be zoom/panned.
 * Parametrize: TODO: yes please
 *
 * @param mapId
 * @constructor
 */
const FacilityMap: React.FC<DetailMapProps> = ({objectId}) => {

    const {getState} = useContext(MapContext);
    const {getFacilityMapState} = useContext(FacilityMapContext);

    const slideWidth = getComputedStyle(document.documentElement).getPropertyValue('--slide-width'); // get the value of --slide-
    const [loaded, setLoaded] = useState<boolean>(false);
    const [feature, setFeature] = useState<ol.Feature>();
    const [extent, setExtent] = useState<Extent>(new Extent())

    const iconS = new GeoFaIconService();

    const mapRef = useRef<HTMLDivElement>(null);
    const [map] = useState(null);

    const swiperSlide = useSwiperSlide();

    /*
    On component mount.
    Get the feature with the given ID and put it into state var feature
     */
    useEffect(() => {

        let features: ol.Feature[] = getState().allFeatures.filter(
            (o: {
                getProperties: () => { (): any; new(): any; objekt_id: string; };
            }) => o.getProperties().objekt_id === objectId
        )
        if (features.length > 0) {
            let nF = new Feature(features[0].getGeometry());
            if (features.length > 0) {
                nF.setProperties(features[0].getProperties());
            } else {
                console.warn('No feature given for FacilityMap with objekt_id ' + objectId);
            }
            setFeature(nF);
        } else {
            console.warn('FacilityMap: No feature for filter=', objectId)
        }
    }, []);

    const [pointLayer, setPointLayer] = useState(new VectorLayer({
            source: new VectorSource()
        })
    );

    const [lineLayer, seLineLayer] = useState(new VectorLayer({
            source: new VectorSource()
        })
    );

    const [detailPointLayer, setDetailPointLayer] = useState(new VectorLayer({
            source: new VectorSource()
        })
    );

    const [detailLineLayer, setDetailLineLayer] = useState(new VectorLayer({
            source: new VectorSource()
        })
    );

    const [view, setView] = useState<ol.View>(new ol.View({
        projection: 'EPSG:900913',
        zoom: 8,
        center: transform(AppConfig.map.initialMapCenter, "EPSG:4326", "EPSG:900913"),
        enableRotation: AppConfig.map.enableRotation
    }));

    /*
    Lazy loading of the map when the slide is active (swiped into the viewport)
     */
    useEffect(() => {

        if (!loaded && feature) {
            loadMap();
            addFeature()
        }

    }, [feature]);

    useEffect(() => {


    }, [feature]);

    function loadMap() {

        let osm = new OSM();

        const scaleLineControl = new ScaleLine({
            units: 'metric'
        });

        let o = {
            view: view,
            layers: [
                new TileLayer({
                    source: osm,
                    opacity: 0.6
                }),
                detailPointLayer,
                detailLineLayer
            ],
            controls: [],
            overlays: [],
            interactions: defaults({
                altShiftDragRotate: false,
                pinchRotate: false,
                mouseWheelZoom: false,
                doubleClickZoom: false,
                shiftDragZoom: false,
                dragPan: false
            })
        };

        let mapObject = new ol.Map(o);

        mapObject.setTarget(objectId);

        setTimeout(() => {
            mapObject.updateSize();
            setLoaded(true);
        }, 100);
    }

    /*
    Adding the feature and doing an elegant pan and zoom.
     */
    function addFeature() {

        if (feature) {

            let geometry = feature?.getGeometry();
            let nF = new ol.Feature(geometry)
            nF.setProperties(feature.getProperties())

            if (geometry instanceof MultiPoint) {

                let coordinates = geometry.getCoordinates();
                let c = coordinates[0];
                view.setCenter(c);
                nF.setStyle(iconS.getOpenLayersPointStyle(nF.getProperties().facil_ty_k, 30, 99999))
                detailPointLayer?.getSource()?.addFeature(nF);
            }

            if (geometry instanceof MultiLineString) {

                detailLineLayer.getSource()?.clear();

                detailLineLayer.getSource()?.addFeature(nF);
                nF.setStyle(iconS.getOpenLayersLineStyle(nF.getProperties().rute_ty_k, 3))

                // Zoom pan to polygon
                const simpleGeometry: SimpleGeometry = (nF.getGeometry() as SimpleGeometry);
                setTimeout(() => {
                    view.fit(simpleGeometry, {
                        padding: [50, 50, 50, 50],
                        duration: 2000,
                    });
                }, 500);

            }

            // TODO: Add Polygons


        }
    }

    return (
        <>
            <div className={styles.mapContainerOne}>
                <FacilityMapContext.Provider value={map!}>
                    <div ref={mapRef}
                         className={styles.mapContainer}
                         id={objectId}>
                    </div>
                </FacilityMapContext.Provider>
                {/*
                <IonItem className="ion-align-items-start">
                    <IonText onClick={panZoomOnMap} className={styles.moreButton} slot="end">
                        find på kortet
                        <IonIcon icon={arrowForwardOutline}></IonIcon>
                    </IonText>
                </IonItem>
                */}
            </div>
        </>
    )
}

export default FacilityMap;
