import * as React from "react";
import {useContext, useEffect, useState} from "react";
import MapContext from "../../../contexts/MapContext";
import {IThemegroup} from "../../../interfaces/ICategoryItem";
import FilterListItem from "../FilterListItem/FilterListItem";
import styles from "./AccordionThemeItem.module.css";
import {CommonFilterItem} from "../../../contexts/MapContextProvider";
import {ESource} from "../../../services/enumarations";

function removeElementFromArray(array: any[], element: any) {
    const index = array.indexOf(element, 0);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}

interface AccordionThemeItemProps {
    category: (IThemegroup | CommonFilterItem),
    source: string
}
// Type guard function
// TODO: put into helpers.ts 2025
function isCommonFilterItem(obj: any): obj is CommonFilterItem {
    return (
        obj &&
        typeof obj === 'object' &&
        typeof obj.category === 'number' &&
        Object.values(ESource).includes(obj.source)
    );
}

const AccordionThemeItem: React.FC<AccordionThemeItemProps> = ({
                                                                   category,
                                                                   source
                                                               }) => {
    const {getState} = useContext(MapContext);
    const [active, setActive] = useState<boolean>(true);
    const dynamicClassName = active ? 'active' : 'inactive';

    function toggle() {

        let tf;

        tf = getState().themeFilter;

        const inList = tf.indexOf(category) > -1;
        if (inList) {
            // remove
            tf = removeElementFromArray(tf, category);
        } else {
            // add
            tf.push(category)
        }
        console.log('toggle:  ', tf.map((t: number) => t));
        getState().setThemeFilter(tf.map((t: number) => t));

    }

    /**
     * Listen to the themeFilter and adapt 'active'
     */
    useEffect(() => {
        if (getState().themeFilter.length === 0) {
            // Nothing is selected
            setActive(true)
        } else {
            // Some theme is active
            if (getState().themeFilter.indexOf(category) === -1) {
                setActive(false)
            } else {
                setActive(true);
            }
        }
    }, [getState()]);

    return (
        <div className={`${styles.accordionThemeItem} ${styles.padding} ${styles[dynamicClassName]}`}
             slot="content"
             onClick={() => toggle()}>
            <FilterListItem category={category} source={source}></FilterListItem>
        </div>
    )
};
export default AccordionThemeItem;
