// import {EGeoType, IBaseTheme, IPointStore, IPointTheme} from "./GeoFaIconService";

import VDCastle from "./pictograms/vdpoints/VDCastle.svg";
import VDFarm from "./pictograms/vdpoints/VDFarm.svg";
import VDNightclubBar from "./pictograms/vdpoints/VDNightclubBar.svg";
import VDBedAndBreakfast from "./pictograms/vdpoints/VDBedAndBreakfast.svg";
import VDMuseum from "./pictograms/vdpoints/VDMuseum.svg";

import FallbackSVG from "./pictograms/points/FALLBACK.svg";

import {Icon, Style} from "ol/style";
import {CommonFilterItem} from "../contexts/MapContextProvider";
import {IBaseTheme, IPointStore, IPointTheme} from "./interfaces";
import {EGeoType} from "./enumarations";

export class VDIconService {

    pointStyleStore: IPointStore = {};

    PRODUCT_POINT: Dictionary<IPointTheme> = {
        13: {
            pictogram: VDMuseum,
            label: 'Museum',
            label_de: 'Museum',
            label_gb: 'Museum',
            pictogramColor: '#cd2226'
        },
        16: {
            pictogram: VDCastle,
            label: 'Slotte og herregårde',
            label_de: 'Schlösser & Herrensitze',
            label_gb: 'Castles and manor houses',
            pictogramColor: '#cd2226'
        },
        22: {
            pictogram: VDBedAndBreakfast,
            label: 'Bed & Breakfast',
            label_de: 'Bed & Breakfast',
            label_gb: 'Bed & Breakfast',
            pictogramColor: '#cd2226'
        },
        23: {
            pictogram: VDFarm,
            label: 'Farm',
            label_de: 'Bauernhöfe',
            label_gb: 'Farms',
            pictogramColor: '#cd2226'
        },
        107: {
            pictogram: VDNightclubBar,
            label: 'Natklubber og barer',
            label_de: 'Nachtleben & Party',
            label_gb: 'Nightlife and Clubs',
            pictogramColor: '#cd2226'
        }
    }

    /*
    Get the pictogram source
     */
    public getSvgSrc(fi: CommonFilterItem): any {
        return this.PRODUCT_POINT[fi.category]?.pictogram;
    }

    public getSvgColor(fi: CommonFilterItem): any {
        return this.PRODUCT_POINT[fi.category]?.pictogramColor;
    }

    public getPointStyleFromStore(id: string, width: number): Style | undefined {

        if (width in this.pointStyleStore!) {
            return this.pointStyleStore![width][id];
        } else {
            return undefined
        }
    }

    public getOpenLayersPointStyle(id: string, width?: number, zIndex?: number, fid?: string) {

        const zIx = zIndex || -1;

        // Let's build a fallback for the themes that are not defined here
        let x = this.PRODUCT_POINT[id]?.pictogram;
        if (!x) {
            console.warn('Fallback: (FACILITY_POINT): ', id)
            x = FallbackSVG;
        }

        let style = this.getPointStyleFromStore(id, width || 30)

        if (style) {
        } else {
            console.log('no style', id, fid);
            style = new Style({
                image: new Icon({
                    src: x,
                    width: width || 30
                }),
                zIndex: zIx
            });
            this.setPointStyleInStore(id, width || 30, style)
        }

        // TODO: put box shadow here

        return style;

    }

    /**
     * Get the product category given its id
     * @param categoryId: The VD Category ID like so: https://api.guidedanmark.org/DataHelp/Categories
     */
    public getGeotype(categoryId: number): EGeoType {

        let t: EGeoType;

        // Easy, VD has only points atm
        return EGeoType.VDPoint;
    }

    public setPointStyleInStore(id: string, width: number, style: Style) {

        if (width in this.pointStyleStore) {
            this.pointStyleStore[width][id] = style
        } else {
            this.pointStyleStore[width] = {}
            this.pointStyleStore[width][id] = style
        }
    }

    /*
    TODO: Rename getCategory(id: number)
     */
    public getTheme(themeNr: number): IBaseTheme | undefined {
        return this.PRODUCT_POINT[themeNr];
    }
}
