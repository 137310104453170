import React, {useCallback, useMemo, useRef, useState} from "react";
import MapContext from "./MapContext";
import * as ol from "ol";
import {ICategoryItem, IThemegroup} from "../interfaces/ICategoryItem";
import {EGeoType, ESource} from "../services/enumarations";

interface MapContextProviderProps {
    children: React.ReactNode
}

// Define the StoreDictionary interface
interface IGUIDObjects {
    [key: string]: any;
}

export interface CommonFilterItem {
    category: number
    source: ESource
    type: EGeoType
}

const MapContextProvider: React.FC<MapContextProviderProps> = ({children}) => {

    const stateRef= useRef({})

    const getState = useCallback(() => stateRef.current, [])

    const [kommunekode, setKommunekode] = useState<number>(550)

    const [mapObject, setMapObject] = useState<ol.Map>(new ol.Map());
    const [Facilities, setFacilities] = useState<ol.Feature[]>([]);

    // A list of theme numbers or theme group definitions
    const [themeFilter, setThemeFilter] =
        useState<(IThemegroup | CommonFilterItem)[]>([])

    const [openTheme, setOpenTheme] = useState<number>(0);

    const [tappedFeatures, setTappedFeatures] =
        useState<ol.Feature[]>([]);

    const [featuresInViewport, setFeaturesInViewport] =
        useState<ol.Feature[]>([])

    const [allFeatures, setAllFeatures] =
        useState<ol.Feature[]>([]);

    const [allVDFeatures, setAllVDFeatures] =
        useState<ol.Feature[]>([]);

    // We store a list of objectIDs to figure out, how far we push the modal up.
    const [objectIDsWithPhotos, setObjectIDsWithPhotos] =
        useState<string[]>([])

    // The feature currently shown in the MapOverlay in the MODAL
    // const [featureInMapOverlay, setFeatureInMapOverlay] = useState<ol.Feature>(new ol.Feature())
    const [featureIdInMapOverlay, setFeatureIdInMapOverlay] =
        useState<string | undefined>(undefined)

    const [overlaySwiperIndex, setOverlaySwiperIndex] = useState(0);

    // Size in pixel of the slide element (HTML) - we need this one to adapt the height of the swiper
    // const [overlaySwiperVisibleSlideHeight, setOverlaySwiperVisibleSlideHeight] = useState<number>(0);

    // We increase this one everytime the image in the imageswiper in the current slide is loaded - then we re-calculate the height
    // const [overlapSwiperVisibleSlideEventCounter, setOverlapSwiperVisibleSlideEventCounter] = useState<number>(0)

    // Define where you came from in the list modal
    const [listModalState, setListModalState] = useState<string>('list')
    const [selectedObjectId, setSelectedObjectId] = useState<string>('')

    // And the modals
    const [showListModal, setShowListModal] = useState<boolean>(false);
    const [selectedLongListCategory, setSelectedLongListCategory] = useState<ICategoryItem>();

    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const [showMunicipalitiesModal, setShowMunicipalitiesModal] = useState<boolean>(false)

    const [tapCoordinate, setTapCoordinate]= useState<any[]>()

    //TODO: into Enum.   0: initial, 1: error, 2: tracking, 3: tracking off
    const [locationStatus, setLocationStatus] = useState<number>(0);


    const contextState = useMemo(()=> ({
        getState,
        featuresInViewport,setFeaturesInViewport,
        kommunekode, setKommunekode,
        Facilities, setFacilities,
        mapObject, setMapObject,
        themeFilter, setThemeFilter,
        tappedFeatures, setTappedFeatures,
        allFeatures, setAllFeatures,
        allVDFeatures, setAllVDFeatures,
        featureIdInMapOverlay, setFeatureIdInMapOverlay,
        objectIDsWithPhotos, setObjectIDsWithPhotos,
        overlaySwiperIndex, setOverlaySwiperIndex,
        listModalState, setListModalState,
        selectedObjectId, setSelectedObjectId,
        showListModal, setShowListModal,
        showFilterModal, setShowFilterModal,
        showMunicipalitiesModal, setShowMunicipalitiesModal,
        selectedLongListCategory, setSelectedLongListCategory,
        tapCoordinate, setTapCoordinate,
        locationStatus, setLocationStatus
    }), [
        getState,
        featuresInViewport,setFeaturesInViewport,
        Facilities, setFacilities,
        kommunekode, setKommunekode,
        mapObject, setMapObject,
        themeFilter, setThemeFilter,
        openTheme, setOpenTheme,
        tappedFeatures, setTappedFeatures,
        allFeatures, setAllFeatures,
        allVDFeatures, setAllVDFeatures,
        featureIdInMapOverlay, setFeatureIdInMapOverlay,
        objectIDsWithPhotos, setObjectIDsWithPhotos,
        overlaySwiperIndex, setOverlaySwiperIndex,
        listModalState, setListModalState,
        selectedObjectId, setSelectedObjectId,
        showListModal, setShowListModal,
        showFilterModal, setShowFilterModal,
        showMunicipalitiesModal, setShowMunicipalitiesModal,
        selectedLongListCategory, setSelectedLongListCategory,
        tapCoordinate, setTapCoordinate,
        locationStatus, setLocationStatus
    ])

    stateRef.current = contextState;

    return (
        <MapContext.Provider value={contextState}>
            {children}
        </MapContext.Provider>
    )
};

export default MapContextProvider;
