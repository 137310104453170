import MUNICIPALITIES from "../Municipalities";

interface ExtendedMunicipalityMap {
    [key: string]: { kommunenr: number, navn: string };
}

interface ID {
    kommunenr: number
    navn: string,
}

export const EXTENDEDMUNICIPALITIES: ExtendedMunicipalityMap = {
    "glim-1010-glop-1470": { kommunenr: 101, navn: "København" },
    "flib-1510-flop-1530": { kommunenr: 147, navn: "Frederiksberg" },
    "zorp-1550-dax-1570": { kommunenr: 151, navn: "Ballerup" },
    "blip-1590-fizz-1610": { kommunenr: 153, navn: "Brøndby" },
    "wump-1650-glub-1670": { kommunenr: 155, navn: "Dragør" },
    "plum-1570-zig-1590": { kommunenr: 157, navn: "Gentofte" },
    "buzz-1610-dax-1630": { kommunenr: 159, navn: "Gladsaxe" },
    "glip-1630-wump-1650": { kommunenr: 161, navn: "Glostrup" },
    "flop-1650-buzz-1670": { kommunenr: 163, navn: "Herlev" },
    "dax-1670-glim-1690": { kommunenr: 165, navn: "Albertslund" },
    "flub-1690-blip-1730": { kommunenr: 167, navn: "Hvidovre" },
    "glop-1730-flib-1750": { kommunenr: 169, navn: "Høje-Taastrup" },
    "fizz-1750-wump-1830": { kommunenr: 175, navn: "Rødovre" },
    "glub-1830-plum-1850": { kommunenr: 183, navn: "Ishøj" },
    "zig-1850-zorp-1870": { kommunenr: 185, navn: "Tårnby" },
    "blip-1870-flub-1900": { kommunenr: 187, navn: "Vallensbæk" },
    "wump-1900-glop-2010": { kommunenr: 190, navn: "Furesø" },
    "plum-2010-fizz-2100": { kommunenr: 201, navn: "Allerød" },
    "glip-2100-buzz-2170": { kommunenr: 210, navn: "Fredensborg" },
    "flop-2170-dax-2190": { kommunenr: 217, navn: "Helsingør" },
    "dax-2190-flib-2230": { kommunenr: 219, navn: "Hillerød" },
    "flub-2230-glim-2300": { kommunenr: 223, navn: "Hørsholm" },
    "glop-2300-fizz-2400": { kommunenr: 230, navn: "Rudersdal" },
    "flub-2400-glub-2500": { kommunenr: 240, navn: "Egedal" },
    "blip-2500-wump-2530": { kommunenr: 250, navn: "Frederikssund" },
    "wump-2530-plum-2590": { kommunenr: 253, navn: "Greve" },
    "plum-2590-buzz-2600": { kommunenr: 259, navn: "Køge" },
    "glip-2600-zorp-2650": { kommunenr: 260, navn: "Halsnæs" },
    "fizz-2650-flib-2690": { kommunenr: 265, navn: "Roskilde" },
    "glub-2690-dax-2700": { kommunenr: 269, navn: "Solrød" },
    "flib-2700-glop-3060": { kommunenr: 270, navn: "Gribskov" },
    "glim-3060-fizz-3160": { kommunenr: 306, navn: "Odsherred" },
    "flub-3160-blip-3200": { kommunenr: 316, navn: "Holbæk" },
    "glip-3200-wump-3260": { kommunenr: 320, navn: "Faxe" },
    "fizz-3260-glop-3290": { kommunenr: 326, navn: "Kalundborg" },
    "glub-3290-flib-3300": { kommunenr: 329, navn: "Ringsted" },
    "plum-3300-zorp-3360": { kommunenr: 330, navn: "Slagelse" },
    "wump-3360-buzz-3400": { kommunenr: 336, navn: "Stevns" },
    "blip-3400-glim-3500": { kommunenr: 340, navn: "Sorø" },
    "glop-3500-fizz-3600": { kommunenr: 350, navn: "Lejre" },
    "flib-3600-glub-3700": { kommunenr: 360, navn: "Lolland" },
    "glip-3700-wump-3760": { kommunenr: 370, navn: "Næstved" },
    "fizz-3760-flub-3900": { kommunenr: 376, navn: "Guldborgsund" },
    "glub-3900-blip-4000": { kommunenr: 390, navn: "Vordingborg" },
    "blip-4000-wump-4100": { kommunenr: 400, navn: "Bornholm" },
    "wump-4100-plum-4110": { kommunenr: 410, navn: "Middelfart" },
    "plum-4110-glop-4200": { kommunenr: 411, navn: "Christiansø" },
    "glip-4200-zorp-4300": { kommunenr: 420, navn: "Assens" },
    "fizz-4300-flub-4400": { kommunenr: 430, navn: "Faaborg-Midtfyn" },
    "butterfly-0440-cupcake-0450": { kommunenr: 440, navn: "Kerteminde" },
    "unicorn-0450-rainbow-0461": { kommunenr: 450, navn: "Nyborg" },
    "penguin-0461-pineapple-0479": { kommunenr: 461, navn: "Odense" },
    "sunshine-0479-bubblegum-0480": { kommunenr: 479, navn: "Svendborg" },
    "dragon-0480-marshmallow-0482": { kommunenr: 480, navn: "Nordfyns" },
    "jellybean-0482-watermelon-0492": { kommunenr: 482, navn: "Langeland" },
    "moonlight-0492-stardust-0510": { kommunenr: 492, navn: "Ærø" },
    "cupcake-0510-sunflower-0530": { kommunenr: 510, navn: "Haderslev" },
    "rainbow-0530-butterfly-0540": { kommunenr: 530, navn: "Billund" },
    "marshmallow-0540-unicorn-0550": { kommunenr: 540, navn: "Sønderborg" },
    "flamingo-0550-banana-0561": { kommunenr: 550, navn: "Tønder" },
    "watermelon-0561-mermaid-0563": { kommunenr: 561, navn: "Esbjerg" },
    "cupcake-0563-rainbow-0573": { kommunenr: 563, navn: "Fanø" },
    "sunflower-0573-bubblegum-0575": { kommunenr: 573, navn: "Varde" },
    "unicorn-0575-marshmallow-0580": { kommunenr: 575, navn: "Vejen" },
    "butterfly-0580-sunshine-0607": { kommunenr: 580, navn: "Aabenraa" },
    "jellybean-0607-flamingo-0615": { kommunenr: 607, navn: "Fredericia" },
    "moonlight-0615-rainbow-0621": { kommunenr: 615, navn: "Horsens" },
    "sunflower-0621-butterfly-0630": { kommunenr: 621, navn: "Kolding" },
    "rainbow-0630-jellybean-0657": { kommunenr: 630, navn: "Vejle" },
    "flamingo-0657-unicorn-0661": { kommunenr: 657, navn: "Herning" },
    "banana-0661-rainbow-0665": { kommunenr: 661, navn: "Holstebro" },
    "mermaid-0665-sunshine-0671": { kommunenr: 665, navn: "Lemvig" },
    "bubblegum-0671-jellybean-0706": { kommunenr: 671, navn: "Struer" },
    "marshmallow-0706-flamingo-0707": { kommunenr: 706, navn: "Syddjurs" },
    "sunflower-0707-cupcake-0710": { kommunenr: 707, navn: "Norddjurs" },
    "rainbow-0710-butterfly-0727": { kommunenr: 710, navn: "Favrskov" },
    "jellybean-0727-mermaid-0730": { kommunenr: 727, navn: "Odder" },
    "moonlight-0730-sunflower-0740": { kommunenr: 730, navn: "Randers" },
    "sunshine-0740-banana-0741": { kommunenr: 740, navn: "Silkeborg" },
    "flamingo-0741-unicorn-0746": { kommunenr: 741, navn: "Samsø" },
    "banana-0746-rainbow-0751": { kommunenr: 746, navn: "Skanderborg" },
    "mermaid-0751-sunshine-0756": { kommunenr: 751, navn: "Aarhus" },
    "bubblegum-0756-jellybean-0760": { kommunenr: 756, navn: "Ikast-Brande" },
    "marshmallow-0760-flamingo-0766": { kommunenr: 760, navn: "Ringkøbing-Skjern" },
    "sunflower-0766-cupcake-0773": { kommunenr: 766, navn: "Hedensted" },
    "rainbow-0773-butterfly-0779": { kommunenr: 773, navn: "Morsø" },
    "jellybean-0779-mermaid-0787": { kommunenr: 779, navn: "Skive" },
    "moonlight-0787-sunflower-0791": { kommunenr: 787, navn: "Thisted" },
    "sunshine-0791-banana-0810": { kommunenr: 791, navn: "Viborg" },
    "flamingo-0810-unicorn-0813": { kommunenr: 810, navn: "Brønderslev" },
    "banana-0813-rainbow-0820": { kommunenr: 813, navn: "Frederikshavn" },
    "mermaid-0820-sunshine-0825": { kommunenr: 820, navn: "Vesthimmerlands" },
    "bubblegum-0825-jellybean-0840": { kommunenr: 825, navn: "Læsø" },
    "marshmallow-0840-flamingo-0846": { kommunenr: 840, navn: "Rebild" },
    "sunflower-0846-cupcake-0849": { kommunenr: 846, navn: "Mariagerfjord" },
    "rainbow-0849-butterfly-0851": { kommunenr: 849, navn: "Jammerbugt" },
    "jellybean-0851-mermaid-0860": { kommunenr: 851, navn: "Aalborg" },
    "moonlight-0860-sunflower-0870": { kommunenr: 860, navn: "Hjørring" },
    "midnight-1300-orchid-1330": { kommunenr: 173, navn: "Lyngby-Taarbæk" },
    "styrelsen": { kommunenr: 999, navn: "Styrelsen" }
}

function getExtendedMunicipalitydName(guid: string | undefined): string | undefined {
    if (guid) {
        return EXTENDEDMUNICIPALITIES[guid].navn;
    }
    else {
        return undefined;
    }
}

export function getByKommunenr(kommunenr: number): { kommunenr: number, navn: string } | undefined {
    const municipalityEntries = Object.values(EXTENDEDMUNICIPALITIES);

    for (const entry of municipalityEntries) {
        if (entry.kommunenr === kommunenr) {
            return entry;
        }
    }

    return undefined; // Return undefined if no entry is found for the provided municipalCodes
}

export default getExtendedMunicipalitydName;
